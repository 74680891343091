<template>
  <v-container fluid>
    <v-expansion-panel class="mb-2" inset v-model="open">
      <v-expansion-panel-content class="CSP_BoarderStyle">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
            <span class="pl-2"></span>
          </div>
        </template>
        <v-layout column class="mx-5" style="margin-top: -30px">
          <v-flex>
            <v-layout row wrap>
              <v-flex xs6>
                <v-icon left style="font-size: 32px; margin-bottom: -53px"
                  >person_pin</v-icon
                >
                <v-text-field
                  v-model="searchParam.tmis_id"
                  style="margin-left: 40px"
                  label="Head Examiner or Examiner Tmis Id here"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm5 d-flex>
                <v-select
                  box
                  :items="items"
                  v-model="searchParam.examiner_type"
                  label="Select Ex Type"
                  style="margin-top: 14px; margin-left: 20px"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 md12 class="my-3">
            <v-layout justify-center row fill-height align-center>
              <v-btn
                outline
                round
                large
                @click="
                  getExDataList(searchParam.tmis_id, searchParam.examiner_type)
                "
              >
                <v-icon left dark>search</v-icon>Search
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-layout row wrap style="margin-bottom: 20%">
      <v-flex
        xs12
        style="margin-top: 20px; padding-left: 32px; padding-right: 32px"
      >
        <v-card flat class="my-0" style="background-color: #f2f2f2">
          <v-card-title class="indigo white--text headline">
            Head Examiner wise Examiner List
            <v-divider></v-divider>
             <input
              type="file"
              ref="fileInput"
              style="display: none;"
              @change="onFileChange"
            />
            <v-btn
              color="success"
              dark
              style="margin-top: -1%; margin-bottom: -1%"
              @click="triggerFileUpload"
            >
              <v-icon left dark>add</v-icon>
              <span>Import Examiner</span>
            </v-btn>
            <addNewExaminer></addNewExaminer>
          </v-card-title>

          <v-layout justify-space-between pa-3>
            <v-flex xs5>
              <v-treeview
                :active.sync="active"
                :items="getHeadWiseExaminerList"
                :item-children="'examiner_list'"
                :item-key="'tims_id'"
                :return-object="true"
                activatable
                active-class="green--text"
                class="grey lighten-5"
                open-on-click
                transition
              >
                <template v-slot:prepend="{ item, active }">
                  <v-icon
                    v-if="!item.children"
                    :color="active ? 'primary' : ''"
                  >
                    mdi-account
                  </v-icon>
                  <span style="font-size: 16px">
                    {{ item.tims_id }} - {{ item.examiner_name }}</span
                  >
                </template>
              </v-treeview>
            </v-flex>
            <v-flex d-flex text-xs-center>
              <v-scroll-y-transition mode="out-in">
                <div
                  v-if="!active.length"
                  class="title grey--text text--lighten-1 font-weight-light"
                  style="align-self: center"
                >
                  Select an Examiner
                </div>
                <v-card
                  v-else
                  class="pt-4 mx-auto"
                  flat
                  max-width="400"
                  style="margin-top: 0%"
                >
                  <template v-if="getHeadWiseExaminerList != ''">
                    <div class="text-xs-center">
                      <v-dialog v-model="newPaperDialog" persistent width="500">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="success"
                            style="margin-left: 61%; margin-top: -4%"
                            dark
                            @click="getParamData()"
                            v-on="on"
                          >
                            <v-icon left dark>add</v-icon> new paper
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                          >
                            Assign New Paper
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              flat
                              @click="AddnewExaminer()"
                            >
                              I accept
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </template>
                  <div v-if="getHeadWiseExaminerList != ''">
                    <v-card-text>
                      <img
                        src="@/assets/nu_logos/avatar.png"
                        style="
                          color: #fff;
                          width: 30% !important;
                          margin-top: -10%;
                        "
                      />
                      <h3 class="headline mb-2">
                        TMIS ID: {{ active[0].tims_id }}
                      </h3>
                      <div class="blue--text subheading font-weight-bold">
                        {{ active[0].examiner_name }}
                      </div>
                      <div
                        class="blue--text mb-2"
                        v-if="active[0].examiner_type == 'E'"
                      >
                        User type: Examiner
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-flex tag="strong" xs5 text-xs-right mr-3 mb-2
                      >Username:</v-flex
                    >
                    <v-flex>{{ active[0].username }}</v-flex>
                    <v-flex>
                      <strong>password:</strong>
                      {{ active[0].password }}</v-flex
                    >
                    <v-flex
                      style="
                        padding-left: 20px;
                        padding-right: 20px;
                        text-align: left;
                      "
                    >
                      <strong style="font-size: 18px"
                        >List of Assigned Papers</strong
                      >
                      <template>
                        <v-data-table
                          :headers="headers"
                          :items="active[0].assigned_papers"
                          hide-actions
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td class="text-xs-center">
                                {{ props.item.paper.pap_id }}
                              </td>
                              <td class="text-xs-left">
                                {{ props.item.paper.pap_name }}
                              </td>
                              <td class="text-xs-center">
                              <v-btn
                                color="primary"
                                dark
                                fab
                                small
                                @click="
                                  editExaminarData(
                                    props.item.paper.pap_id
                                  )
                                "
                              >
                                <v-icon dark>edit</v-icon>
                              </v-btn>
                              <v-btn
                                color="red"
                                dark
                                fab
                                small
                                @click="DeleteExaminarData(props.item.paper.session_id,props.item.userpin)"
                              >
                                <v-icon dark>delete</v-icon>
                              </v-btn>
                            </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </template>
                    </v-flex>
                  </div>
                </v-card>
              </v-scroll-y-transition>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";
import addNewExaminer from "./addNewExaminer.vue";
const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  components: {
    addNewExaminer,
  },
  data() {
    return {
      searchParam: {},
      active: [],
      avatar: null,
      open: [],
      users: [],
      examiners: {},
      items: ["Head-Examiner", "Examiner"],
      pagination: {
        sortBy: "name",
      },
      headers: [
        { text: "Paper Code", value: "pap_code" },
        { text: "Paper Name", value: "" },
        { text: "Action", value: "fat" },
      ],
      newPaperDialog: "",
      newexaminerDialog: "",
      exTmis: {},
    };
  },
  created() {
    //this.getExDataList();
  },
  computed: {
    getHeadWiseExaminerList() {
      return this.$store.getters.examinerList;
    },
    selected() {
      if (!this.active.length) return undefined;
      const id = this.active[0];
      return this.users.find((user) => user.tims_id === id);
    },
  },
  watch: {
    selected: "randomAvatar",
  },
  methods: {
    getExDataList(tmis, type) {
      let peram = {};
      peram.tims_id = tmis;
      if (type == "Examiner") {
        peram.examiner_type = "E";
      } else {
        peram.examiner_type = "H";
      }
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      this.loading = true;
      console.log("Tanvir ex paper change", peram);
      this.$store
        .dispatch("fetchExaminerPaperChangeDataList", peram)
        .then((data) => {
          console.log("Tanvir! called", data);
        });
    },

    getParamData() {
      //this.exTmis = this.getHeadWiseExaminerList.map(item => item.tims_id);
      this.exTmis = this.getHeadWiseExaminerList.forEach((item) => {
        this.exTmis[item.tmis_id] = item;
      });
      console.log("Tanvir! this is TMIS", this.exTmis);
    },

    editExaminarData(pap_id){
      console.log('Tanvir! This is edit data', pap_id);
    },

    DeleteExaminarData(session_id, userpin){
      let peram = {};
      peram.tims_id = userpin;
      peram.session_id = session_id;
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      this.loading = true;
      console.log("Tanvir ex paper change", peram);
      this.$store
        .dispatch("deleteExaminer", peram)
        .then((data) => {
          console.log("Tanvir! called", data);
          this.getExDataList();
        });
    },

    AddnewPaper() {
      this.newPaperDialog = false;
    },

    AddnewExaminer() {
      this.newPaperDialog = false;
    },

    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile) {
        this.uploadFile();
      }
    },
    uploadFile() {
      if (this.selectedFile) {
        const formData = new FormData();
        formData.append('ex_files', this.selectedFile);
        this.$store
        .dispatch("postNewExaminerData", formData)
        .then((data) => {
          console.log("Tanvir! called", data);
        });
      } else {
        console.error("No file selected.");
      }
    },
    
  },
};
</script>

<style></style>
