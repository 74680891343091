<template>
  <div class="text-xs-center">
    <v-dialog v-model="newExaminerDialog" persistent width="500">
      <template v-slot:activator="{ on }">
        <v-btn
          color="success"
          dark
          style="margin-top: -1%; margin-bottom: -1%"
          v-on="on"
        >
          <v-icon left dark>add</v-icon>
          <span v-if="getHeadWiseExaminerList != ''">new Examiner</span>
          <span v-else>new Head Examiner</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Privacy Policy
        </v-card-title>
        <v-layout column class="mx-5" style="margin-top: 0px">
          <v-flex>
            <v-layout row wrap>
              <v-flex xs12 v-if="getHeadWiseExaminerList != ''">
                <v-icon left style="font-size: 32px; margin-bottom: -53px"
                  >person_pin</v-icon
                >
                <v-text-field
                  v-model="searchParam.ex_tims_id"
                  style="margin-left: 40px"
                  label="Examiner Tmis Id"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 style="margin-top: 0px">
                <v-icon left style="font-size: 32px; margin-bottom: -53px"
                  >file_copy</v-icon
                >
                <v-text-field
                  v-model="searchParam.paper_code"
                  style="margin-left: 40px"
                  label="Paper Code"
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                style="margin-top: -20px"
                v-if="getHeadWiseExaminerList == ''"
              >
                <v-icon left style="font-size: 32px; margin-bottom: -53px"
                  >person_pin</v-icon
                >
                <v-text-field
                  v-model="searchParam.h_ex_tims_id"
                  style="margin-left: 40px"
                  label="Head Examiner Tmis Id"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm12 md12 class="my-3">
            <v-layout justify-center row fill-height align-center>
              <v-btn
                color="success"
                dark
                @click="
                  addNewEx(
                    searchParam.ex_tims_id,
                    searchParam.paper_code,
                    searchParam.h_ex_tims_id
                  )
                "
              >
                <v-icon left dark>done</v-icon>submit
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="closenewExaminer()">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";

export default {
  mixins: [commonGet, commonExamYear, common_login_getters],

  data() {
    return {
      searchParam: {},
      newExaminerDialog: "",
    };
  },
  created() {},
  computed: {
    getHeadWiseExaminerList() {
      return this.$store.getters.examinerList;
    },
  },
  watch: {},
  methods: {
    getExDataList() {
      let peram = {};
      peram.tims_id = this.getHeadWiseExaminerList[0].tims_id;
      peram.examiner_type = "H";
      peram.exam_code = this.getLoggedInExam.code;
      peram.exam_year = this.getLoggedInYear.year;
      this.loading = true;
      console.log("Tanvir ex paper change", peram);
      this.$store
        .dispatch("fetchExaminerPaperChangeDataList", peram)
        .then((data) => {
          console.log("Tanvir! called", data);
        });
    },

    addNewEx(ex_tims_id, paper_code, h_ex_tims_id) {
      let peram = {};
      peram.exam_code = this.searchParam.exam_code;
      peram.exam_year = this.searchParam.year;
      peram.ex_tims_id = ex_tims_id;
      peram.paper_code = paper_code;
      if (h_ex_tims_id) {
        peram.h_ex_tims_id = h_ex_tims_id;
      } else {
        peram.h_ex_tims_id = this.getHeadWiseExaminerList[0].tims_id;
      }
      console.log("Tanvir! This is New Examiner", peram);
      this.$store.dispatch("postNewExaminerData", peram).then((resp) => {
        this.loading = false;
        this.dialog = true;
        this.searchParam.ex_tims_id = "";
        this.searchParam.paper_code = "";
        this.searchParam.h_ex_tims_id = "";
        this.getExDataList();
      });
    },
    closenewExaminer() {
      this.newExaminerDialog = false;
      this.searchParam.ex_tims_id = "";
      this.searchParam.paper_code = "";
      this.searchParam.h_ex_tims_id = "";
    },
  },
};
</script>

<style></style>
